var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"Groups","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"v-card--material pa-3"},[_c('card-heading',{attrs:{"color":_vm.sectionColor,"title":'Bonus Groups'},scopedSlots:_vm._u([{key:"append",fn:function(){return [(
								_vm._usrFlagsSome({
									key: 'bonus.groups.detail',
									val: _vm.permissions.CREATE,
								})
							)?_c('AddGroup',{attrs:{"color":_vm.sectionColor + ' lighten-1',"configs":_vm.configListLocal,"themes":_vm.themesListLocal,"configLoading":_vm.configLoading,"themesLoading":_vm.themesLoading},on:{"addingGroup":_vm.loadAddGroupData,"reload":_vm._loadTable}}):_vm._e()]},proxy:true}])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.filteredHeaders,"items":_vm.list,"options":_vm.options,"server-items-length":_vm.total_records,"item-class":_vm.itemClass,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function({ pagination }){return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-autocomplete',{staticClass:"mx-3 col-3",attrs:{"small-chips":"","clearable":"","hide-details":"","label":`Casino`,"filter":_vm.filterFn,"items":_vm.allowedCasinos,"item-value":"casino_id","item-text":"website"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.website))]),_c('v-spacer'),_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.casino_id))])]}}],null,true),model:{value:(_vm._casinoFilter),callback:function ($$v) {_vm._casinoFilter=$$v},expression:"_casinoFilter"}}),_c('v-data-footer',_vm._b({staticClass:"anton",attrs:{"options":_vm.options,"pagination":pagination},on:{"update:options":function($event){_vm.options=$event}}},'v-data-footer',_vm.footerProps,false))],1)]}},{key:`item.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" "),(
								item.deleted &&
								_vm._usrFlagsSome({
									key: 'bonus.group_themes.list',
									val: _vm.permissions.DELETE,
								})
							)?_c('v-chip',{staticClass:"ml-3",attrs:{"small":"","color":"error"}},[_vm._v(" Deleted ")]):_vm._e()]}},{key:`item.group_config_id`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.group_config_id)+" ")]}},(
							_vm._usrFlagsSome({
								key: ['bonus.group_themes.detail'],
								val: _vm.permissions.READ,
							})
						)?{key:`item.theme_id`,fn:function({ item }){return [_c('v-avatar',{staticClass:"hoverTheme body-2 font-weight-bold primary--text",attrs:{"size":"26px"},on:{"click":function($event){$event.preventDefault();return _vm.toTheme(item)}}},[_vm._v(" "+_vm._s(item.theme_id)+" ")])]}}:null,{key:`item.casinos`,fn:function({ item }){return [_vm._l((item.casinos.slice(0, _vm.maxChips)),function(casino){return _c('v-chip',{key:`casinochip-${casino}`,class:`px-2 mr-1 secondary ${
								_vm.$vuetify.theme.dark ? '' : 'darken-2'
							}`,attrs:{"small":""}},[_vm._v(" "+_vm._s(casino)+" ")])}),_c('v-tooltip',{attrs:{"max-width":"300px","color":"primary darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(item.casinos.length > _vm.maxChips)?_c('span',_vm._g(_vm._b({staticClass:"primary--text"},'span',attrs,false),on),[_vm._v(" +"+_vm._s(item.casinos.length - _vm.maxChips)+" other"+_vm._s(item.casinos.length - _vm.maxChips > 1 ? "s" : "")+" ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"d-flex flex-wrap"},[_vm._v(" "+_vm._s(item.casinos.slice(_vm.maxChips).join(", "))+" ")])])]}},{key:`item.deleted`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("Date")(item.deleted))+" ")]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }